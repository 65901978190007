<template>
  <v-container>
    <v-row align="center" class="mx-0" v-if="selectedLoan.status === 'current'">
      <v-col cols="3"><h4>Outstanding Balance</h4> </v-col>
      <v-col>{{ outstandingBalance | toCurrency }}</v-col>
    </v-row>

    <v-row
      align="center"
      class="mx-0"
      v-if="selectedLoan.status === 'current' && lastTransaction"
    >
      <v-col cols="3"><h4>Last Transaction Date:</h4> </v-col>
      <v-col>{{ lastTransaction.created | filterDate }}</v-col>
    </v-row>

    <v-row
      align="center"
      class="mx-0"
      v-if="selectedLoan.status === 'current' && lastTransaction"
    >
      <v-col cols="3"><h4>Last Transaction Amount:</h4> </v-col>
      <v-col>{{ lastTransaction.amount | toCurrency }}</v-col>
    </v-row>

    <v-row
      align="center"
      class="mx-0"
      v-for="item in orderedItems"
      :key="item.key"
      v-show="!!item.value"
    >
      <v-col cols="3"
        ><h4>{{ item.key | toCase("capitalCase") }}</h4>
      </v-col>
      <v-col v-if="moneyItems.some((x) => x === item.key)">{{
        item.value | toCurrency
      }}</v-col>
      <v-col v-else-if="dateItems.some((x) => x === item.key)">{{
        item.value | filterDate
      }}</v-col>
      <v-col v-else-if="item.key == 'status'">{{
        item.value | displayStatus
      }}</v-col>
      <v-col v-else>{{ item.value }}</v-col>
    </v-row>
  </v-container>
</template>
<script>
import * as changeCase from "change-case";
import statusMixins from "@/mixins/statusMixins.js";

const loanItems = [
  "status",
  "amount_requested",
  "principal",
  "currency",
  "date_applied",
  "date_required",
  "date_approved",
  "date_issued",
  "disbusement_date",
  "term",
  "installment",
  "periodType",
  "type",
  "payment_method",
  "interests",
  "fees",
  "borrower_type",
  "comments",
];

export default {
  name: "LoanDisplay",
  mixins: [statusMixins],
  data() {
    return {
      loanItems,
    };
  },
  filters: {
    toCase(value, type) {
      return changeCase[type](value);
    },

    toCurrency(value) {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      });
      const curValue = formatter.format(Math.abs(value));
      return value >= 0 ? curValue : `(${curValue})`;
    },
    filterDate(value) {
      if (value != 0 && !value) return "";
      const parts = value.split("T");
      //if (parts.length === 1) return value;
      return parts[0];
    },
    filterApplicantName(value, applicants) {
      let applicant = applicants.find((x) => x.userid === value);
      if (!applicant) return "";
      return `${applicant.given_name} ${applicant.family_name}`;
    },
  },
  computed: {
    selectedLoan() {
      return this.$store.state.loan.selectedLoan;
    },
    lastTransaction() {
      return this.$store.getters["loan/lastTransaction"];
    },
    outstandingBalance() {
      return this.$store.getters["loan/outstandingBalance"];
    },
    moneyItems() {
      return [
        "amount_requested",
        "principal",
        "interests",
        "fees",
        "installment",
      ];
    },
    dateItems() {
      return [
        "date_applied",
        "date_required",
        "date_approved",
        "date_issued",
        "disbusement_date",
      ];
    },
    orderedItems() {
      return this.loanItems.map((key) => ({
        key,
        value: this.selectedLoan[key],
      }));
    },
  },
};
</script>