<template>
  <div>
    <v-card v-if="!!htmlData">
      <v-card-text>
        <iframe
          style="width: 100%; height: 600px"
          :srcdoc="htmlData"
          @load="checkUrl"
        ></iframe>
      </v-card-text>
    </v-card>
    <v-card class="mx-auto" v-else-if="selectedLoan">
      <v-toolbar color="cyan" dark flat>
        <v-toolbar-title>
          <slot name="title">Selected Loan</slot>
        </v-toolbar-title>

        <v-spacer></v-spacer>
        <confirm-popup
          v-if="allowWithdrawn(selectedLoan.status)"
          key="withDrawnBtn"
          :loading="loaderId === selectedLoan.id"
          :btn-type="withDrawnBtn"
          @do-action="withdrawLoan(selectedLoan.id)"
        ></confirm-popup>

        <confirm-popup
          v-if="selectedLoan.status === 'current'"
          :disabled="!isCardAvailable"
          key="payNowBtn"
          :loading="loaderId === selectedLoan.id"
          :padded-card-number="
            cardData.CreditCardTransactionResults &&
            cardData.CreditCardTransactionResults.PaddedCardNumber
          "
          :btn-type="payNowBtn"
          @do-action="doCardTransaction($event)"
        ></confirm-popup>

        <v-spacer></v-spacer>

        <v-btn icon @click="$store.commit('loan/SET_LOAN')">
          <v-icon>mdi-exit-to-app</v-icon>
        </v-btn>
      </v-toolbar>
      <v-alert
        v-model="isAlert"
        :type="alertType"
        close-text="Close Alert"
        dismissible
        >{{ alertMessage }}</v-alert
      >

      <v-tabs v-model="loanTabId">
        <v-tab> Summary </v-tab>
        <v-tab> Transactions </v-tab>
      </v-tabs>

      <v-tabs-items v-model="loanTabId">
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <loan-display />
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card flat>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Type</th>
                    <th class="text-left">Date</th>
                    <th class="text-left">Confirmed</th>
                    <th class="text-right">Amount</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="trx in selectedLoan.transactions" :key="trx.id">
                    <td>
                      {{ trx.type }}
                    </td>
                    <td>
                      {{ trx.created | filterDate }}
                    </td>
                    <td>
                      {{ trx.confirmed }}
                    </td>
                    <td class="text-right">
                      {{ trx.amount | toCurrency }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <v-card v-else>
      <v-card-title> Loans </v-card-title>
      <v-data-table
        :headers="headers"
        :items="loans"
        item-key="id"
        class="elevation-1"
        :loading="loading"
      >
        <template v-slot:progress>
          <div class="text-center">
            <v-progress-circular
              :size="70"
              :width="7"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
        </template>
        <template v-slot:[`item.id`]="{ item }">
          <td>
            <v-btn :loading="loaderId === item.id" @click="getLoan(item.id)"
              >Select</v-btn
            >
          </td>
        </template>

        <template v-slot:[`item.userid`]="{ item }">
          <td>{{ item.userid | filterApplicantName(applicants) }}</td>
        </template>
        <template v-slot:[`item.principal`]="{ item }">
          <td>{{ item.principal | toCurrency }}</td>
        </template>
        <template v-slot:[`item.date_applied`]="{ item }">
          <td>{{ item.date_applied | filterDate }}</td>
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <td>{{ (item.status) |  displayStatus }}</td>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import statusMixins from "@/mixins/statusMixins.js";
import ConfirmPopup from "@/components/ConfirmPopup";
import graphqlMixin from "@/mixins/graphql-mixin.js";
import * as changeCase from "change-case";

import LoanDisplay from "@/components/LoanDisplay";

export default {
  mixins: [graphqlMixin, statusMixins],
  components: { ConfirmPopup, LoanDisplay },
  data() {
    return {
      payNowActive: false,
      htmlData: null,

      loanTabId: null,
      loaderId: null,

      withDrawnBtn: {
        isCardForm: false,
        title: "Withdraw Loan?",
        text: "Click the Confirm button to withdraw your loan application.",
        slotButton: {
          color: "error",
          text: "Withdraw Loan",
          dark: true,
        },
        agreeButton: {
          color: "primiary",
          text: "Confirm",
        },
        disagreeButton: {
          color: "primiary",
          text: "Exit",
        },
      },

      preAuthoriseBtn: {
        isCardForm: false,
        isAuth: true,
        title: "Pre-Authorise Card",
        text: "Click to Register Credit/Debit Card for the Loan Repayment.",
        slotButton: {
          color: "primary",
          text: "Register Card",
          dark: true,
        },
        agreeButton: {
          color: "primiary",
          text: "Register",
        },
        disagreeButton: {
          color: "primiary",
          text: "Exit",
        },
      },

      payNowBtn: {
        isCardForm: true,
        isAuth: false,
        title: "Pay Now",
        text: "Enter the Amount and Press the Confirm Button to process Credit/Debit Card Loan Payment.",
        slotButton: {
          color: "primary",
          text: "Pay Now",
          dark: true,
        },
        agreeButton: {
          color: "primiary",
          text: "Confirm",
        },
        disagreeButton: {
          color: "primiary",
          text: "Exit",
        },
      },
    };
  },
  filters: {
    toCase(value, type) {
      return changeCase[type](value);
    },
    toCurrency(value) {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      });
      return formatter.format(value);
    },
    filterDate(value) {
      if (value != 0 && !value) return "";
      const parts = value.split("T");
      //if (parts.length === 1) return value;
      return parts[0];
    },
    filterApplicantName(value, applicants) {
      let applicant = applicants.find((x) => x.userid === value);
      if (!applicant) return "";
      return `${applicant.given_name} ${applicant.family_name}`;
    },
  },
  computed: {
    isCardAvailable() {
      return !!(
        this.cardData.CreditCardTransactionResults &&
        this.cardData.CreditCardTransactionResults.PaddedCardNumber
      );
    },
    cardResponse() {
      return this.$store.state.loan.cardResponse;
    },
    cardData() {
      return this.$store.state.profile.cardData;
    },
    selectedLoan() {
      return this.$store.state.loan.selectedLoan;
    },
    loading() {
      return !this.$store.state.profile.loaded;
    },
    headers() {
      return [
        { text: "Amount", value: "principal", sortable: false },
        { text: "Currency", value: "currency", sortable: false },
        { text: "Date", value: "date_applied", sortable: false },
        { text: "Status", value: "status", sortable: false },
        { text: "", value: "id", sortable: false },
      ];
    },
    loans() {
      return this.$store.state.loan.loans.map((x) => {
        x.principal = x.principal || x.amount_requested;
        return x;
      });
    },

    userid() {
      return this.$store.getters["account/userid"];
    },
  },

  watch: {
    cardData: {
      handler: function (val) {
        console.log("cardData", val);
      },
      deep: true,
    },
    "cardResponse.HTMLFormData": function (_html) {
      const self = this;
      if (self.cardResponse && self.cardResponse.ResponseCode == 0) {
        console.log("html", _html);
        self.htmlData = _html;
      }
    },

    selectedLoan: {
      handler: function (val) {
        console.log("selectedLoan", val);
        if (val) {
          const loan_id = val.id;
          console.log("val", val);
          //const userid = this.userid;
          this.$store.dispatch("loan/monitorLoans", { loan_id });
        }
      },
      deep: true,
    },
    htmlData: function (to) {
      if (to) {
        this.payNowActive = true;
      }
    },
    loans: {
      handler: function (val) {
        console.log("loans", val);
        if (this.payNowActive) {
          this.setAlert("Loan Updated!");
          this.payNowActive = false;
          this.htmlData = null;
        }
      },
      deep: true,
    },
  },

  methods: {
    checkUrl(evt) {
      console.log("evt", evt);
      //this.htmlData = null;
    },
    allowWithdrawn(status) {
      switch (status) {
        case "pending":
        case "in_review":
          return true;
        default:
          return false;
      }
    },
    async withdrawLoan(id) {
      try {
        this.loaderId = id;
        await this.$store.dispatch("loan/withdrawLoan", id);
      } catch (err) {
        this.setGraphQlError(err);
      } finally {
        this.loaderId = null;
      }
    },

    async getLoan(id) {
      try {
        console.log("id", id);
        this.loaderId = id;
        await this.$store.dispatch("loan/getLoan", id);
      } catch (err) {
        this.setGraphQlError(err);
      } finally {
        this.loaderId = null;
      }
    },

    registerCard() {
      this.$router.push("/member/card");
    },

    async doCardTransaction({ amount, cardCVV2, isAuth }) {
      try {
        const loan_id = this.selectedLoan.id;
        this.loaderId = loan_id;
        await this.$store.dispatch("loan/doCardTransaction", {
          loan_id,
          amount,
          cardCVV2,
          isAuth,
        });
        this.loaderId = null;
      } catch (err) {
        this.setGraphQlError(err);
      } finally {
        this.loaderId = null;
      }
    },
  },
};
</script>
