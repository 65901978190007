<template>
  <v-dialog v-model="dialog" persistent width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :disabled="disabled"
        :color="btnType.slotButton.color"
        :dark="btnType.slotButton.dark"
        v-bind="attrs"
        v-on="on"
        :loading="loading"
      >
        {{ btnType.slotButton.text }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="headline">
        {{ btnType.title }}
      </v-card-title>
      <v-card-text v-if="!btnType.isCardForm">{{ btnType.text }}</v-card-text>
      <v-card-text v-if="btnType.isCardForm">
        <p>{{ btnType.text }}</p>
        <v-form ref="paymentForm">
          <v-text-field
            outline
            label="Amount"
            type="number"
            :counter="10"
            :rules="amountRules"
            v-model="actionParamters.amount"
          ></v-text-field>
          <v-text-field
            outline
            label="Card Number Last 4 Numbers"
            type="text"
            :counter="4"
            :rules="last4DigitRules"
            v-model="actionParamters.cardno"
          ></v-text-field>
          <v-text-field
            outline
            label="CVV2"
            type="number"
            :counter="10"
            :rules="cvvRules"
            v-model="actionParamters.cardCVV2"
          ></v-text-field>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :color="btnType.disagreeButton.color"
          text
          @click="dialog = false"
        >
          {{ btnType.disagreeButton.text }}
        </v-btn>
        <v-btn :color="btnType.agreeButton.color" text @click="doAction">
          {{ btnType.agreeButton.text }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
      actionParamters: {
        isAuth: false,
        amount: null,
        cardCVV2: null,
      },
      amountRules: [
        (v) => !!v || "Amount is required",
        (v) =>
          (parseFloat(v) && v >= 60 && v < 100000) || "Amount is not valid",
      ],
      last4DigitRules: [
        (v) => !!v || "Last 4 Digits required",
        (v) =>
          v === this.paddedCardNumber.slice(this.paddedCardNumber.length - 4) ||
          "Must Match Card",
      ],
      cvvRules: [
        (v) => !!v || "CVV required",
        (v) => /^[0-9]{3,8}$/.test(v) || "CVV must be valid",
      ],
    };
  },
  props: ["btnType", "loading", "paddedCardNumber", "disabled"],

  methods: {
    doAction() {
      if (this.btnType.isCardForm) {
        if (this.$refs.paymentForm.validate()) {
          this.actionParamters.isAuth = this.btnType.isAuth;
          this.$emit("do-action", this.actionParamters);
          this.dialog = false;
        }
      } else {
        this.$emit("do-action");
        this.dialog = false;
      }
    },
  },
};
</script>